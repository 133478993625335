<template>
  <ion-page>
    <Header type="0" title="外协模具点检" v-if="menuRole == 'phone'"> </Header>
    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left">外协模具负责人</div>
          <div class="right">
            <ion-text>
              {{ formData.mouldDirector }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="left">供应商</div>
          <div class="right">
            <ion-text>
              {{ formData.supplierCN }}
            </ion-text>
          </div>
        </div>
        <div class="item">
          <div class="item remark">
            <ion-text class="left">
              <span class="required">*</span>外协模具点检表</ion-text
            >
            <div style="width: 100%">
              <upload-img
                @modelBack="modelBack"
                :maxLength="
                  $route.query.isEdit == 1 && (formData.stateId == 1||formData.stateId == 2)
                    ? 100
                    : formData.mouldImage.length
                "
                :form="formData"
                photo="mouldImage"
                :canRemove="$route.query.isEdit == 1 && (formData.stateId == 1||formData.stateId == 2)"
                :id="formData.id"
              ></upload-img>
            </div>
          </div>
        </div>
        <div
          class="item"
          v-if="
            formData.ideaMessage &&
            (formData.stateId == 1 || formData.stateId == 2)
          "
        >
          <div class="item remark">
            <ion-text class="left"> 意见内容</ion-text>
            <ion-textarea
              auto-grow="true"
              class="right"
              maxlength="200"
              v-model="formData.ideaMessage"
              disabled
            ></ion-textarea>
          </div>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="formData.mouldItem.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <check-card
        :dataItem="dataItem"
        v-for="dataItem in formData.mouldItem"
        :key="dataItem.id"
        :isEdit="$route.query.isEdit == '1'"
        :stateId="formData.stateId"
      ></check-card>
      <CardLoading v-if="islist" lodingType="1"></CardLoading>
    </ion-content>
    <ion-footer>
      <!-- <ion-button expand="block" @click="exportWord">导出</ion-button> -->
      <ion-button
        expand="block"
        @click="sendWords"
        v-show="
          $route.query.isEdit == 1 &&
          (formData.stateId == 2 || formData.stateId == 1)
        "
        >发送</ion-button
      >
      <ion-button
        expand="block"
        @click="presentAlertPrompt"
        v-show="$route.query.isEdit == 1 && formData.stateId == 2"
        >驳回</ion-button
      >
    </ion-footer>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import checkCard from "./checkCard.vue";
import {
  getMouldInfo,
  sendTask,
  exportCheckItem,
} from "@/api/templateInfo/templateCheck";
import * as dd from "dingtalk-jsapi";
import Utils from "@/utils/Utils.ts";
import { loadingController, alertController } from "@ionic/vue";
import { FileTransfer } from "@ionic-native/file-transfer";
import SystemUtils from "@/utils/SystemUtils";
import uploadImg from "@/components/getPhotoTemplateCheck/index.vue";
export default {
  components: {
    Header,
    CardLoading,
    checkCard,
    uploadImg,
  },
  data() {
    return {
      islist: false,
      formData: {
        mouldItem: [],
        mouldImage: [],
      },
      menuRole: "phone",
    };
  },
  mounted() {
    document.addEventListener("deviceready", function () {}, false);
    this.getList();
    var _this = this;
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "导出", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            _this.exportWord();
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "外协模具点检", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  methods: {
    getList() {
      this.islist =true
      getMouldInfo({ id: this.$route.params.id }).then((res) => {
        this.islist =false
        this.formData = res.data.data.resultData[0];
      });
    },
    modelBack(data) {
      this.formData.mouldImage = data;
    },
    async sendWords() {
      const _this = this;
      // this.formData.mouldItem.forEach((e) => {
      //   if (
      //     (e.rust == "否" || e.rust == null) &&
      //     (e.damage == "否" || e.damage == null) &&
      //     (e.lose == "否" || e.lose == null) &&
      //     (e.workFace == "否" || e.workFace == null) &&
      //     (e.sign == "否" || e.sign == null) &&
      //     (e.partComplete == "否" || e.partComplete == null) &&
      //     (e.vulnerablePart == "否" || e.vulnerablePart == null) &&
      //     (e.other == "否" || e.other == null)
      //   ) {
      //     Utils.presentToastTopWarning("模具状态不能为空", "danger");
      //     return false;
      //   }
      // });
      if (this.formData.mouldImage.length == 0) {
        Utils.presentToastTopWarning("请上传外协模具点检表", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否发送模具信息",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
              var sendType = _this.formData.stateId == 1 ? 1 : 0;
              sendTask({
                id: _this.formData.id,
                sendType: sendType,
                stateId: _this.formData.stateId,
                userId: SystemUtils.loginUser.id,
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    exportWord() {
      var _this = this;
      exportCheckItem({
        id: this.formData.id,
        supplierCN: this.formData.supplierCN,
      }).then((res) => {
        console.log(res);
        var url = res.data.data;
        var name = "20210811" + new Date().getTime();
        let a = document.createElement("a"); // 生成一个a元素
        let event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    async presentAlertPrompt() {
      var _this = this;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "驳回原因",
        inputs: [
          {
            name: "name8",
            type: "textarea",
            placeholder: "请输入驳回原因",
            cssClass: "specialClass",
            attributes: {
              minlength: 1,
               maxLength:500,
              inputmode: "decimal",
            },
          },
        ],
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async (e) => {
             if (e.name8.trim() == "") {
                Utils.presentToastTopWarning(
                  '请输入驳回原因',
                  "danger"
                );
                return false;
              }
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();
              sendTask({
                id: _this.formData.id,
                sendType: 2,
                stateId: _this.formData.stateId,
                ideaMessage: e.name8.trim(),
                userId: SystemUtils.loginUser.id,
              }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
      });
      return alert.present();
    },
  },
};
</script>
<style lang="scss" scoped>
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
</style>